import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { Provider, cacheExchange, createClient, dedupExchange, mapExchange } from 'urql';
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';

import { AuthProvider } from './contexts/auth';
import App from './App';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';

const client = createClient({
    url: process.env.REACT_APP_BASE_URL as string,
    fetchOptions: () => {
        return {
            headers: { authorization: `Bearer ${sessionStorage.getItem('token')}` }
        };
    },
    exchanges: [
        dedupExchange,
        cacheExchange,
        multipartFetchExchange,
        mapExchange({
            onError(error) {
                const isAuthError = error.graphQLErrors.some(e => e.extensions?.code === 'UNAUTHENTICATED' || e.message === 'UNAUTHENTICATED');
                if (isAuthError) {
                    sessionStorage.removeItem('token');
                    window.location.href = `/login?returnUrl=${encodeURI(window.location.pathname)}`;
                }
            }
        })
    ]
});

const root = createRoot(document.getElementById('root') as Element);
root.render(
    <StrictMode>
        <AuthProvider>
            <Provider value={client}>
                <App />
            </Provider>
        </AuthProvider>
    </StrictMode>
);
