import { useQuery } from 'urql';
import dayjs from 'dayjs';

import notification from '../../notification';

import type { Order } from '@gvdp/common/Order';

export const OrderExport: React.FC = () => {
    const [query] = useQuery({
        query: /* GraphQL */ `
            query {
                orderADExport {
                    owner {
                        profile {
                            completename
                        }
                    }
                    orderno
                    date
                    isCancelled
                    books {
                        book {
                            title
                        }
                        quantity
                    }
                }
            }
        `
    });

    if (query.error) {
        notification.error(query.error);
    }

    return (
        <>
            {query.fetching && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl">Caricamento...</span>
                </div>
            )}

            {query.error && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl text-vdp-red">Si è verificato un errore nel gestire la richiesta</span>
                </div>
            )}
            {query.data && (
                <table className="table-auto w-full">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Cliente</th>
                            <th>Ordine N.</th>
                            <th>Data</th>
                            <th>Annullato</th>
                            <th>Libri</th>
                        </tr>
                    </thead>
                    <tbody>
                        {query.data.orderADExport.map((o: Order, i: number) => (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{o.owner.profile.completename}</td>
                                <td>{o.orderno}</td>
                                <td>{dayjs(o.date).format('DD/MM/YYYY')}</td>
                                <td>{o.isCancelled ? 'SI' : 'NO'}</td>
                                <td className="divider">
                                    {o.books.map(b => (
                                        <span key={b.book.title}>
                                            {b.book.title} ({b.quantity})
                                        </span>
                                    ))}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </>
    );
};
