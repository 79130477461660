import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';
import dayjs from 'dayjs';

import notification from '../../notification';

import type { Order } from '@gvdp/common/Order';

import { Layout } from '../../components/_layout';
import { Select } from '../../components/Select';

export const OrderList: React.FC = () => {
    const limit = 20;
    const [cursor, setCursor] = useState(0);
    const [orderBy, setOrderBy] = useState('date_DESC');

    const [query] = useQuery({
        query: /* GraphQL */ `
            query($limit: Int!, $cursor: Int, $orderBy: OrderOrderByInput) {
                orderADList(limit: $limit, cursor: $cursor, orderBy: $orderBy) {
                    edges {
                        id
                        owner {
                            profile {
                                completename
                            }
                        }
                        orderno
                        date
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                }
            }
        `,
        variables: { limit, cursor, orderBy }
    });

    if (query.error) {
        notification.error(query.error);
    }

    const fetch = (cur: number, order?: string) => {
        setCursor(+(cur !== 0) && (cur || cursor));
        if (order) {
            setOrderBy(order);
        }

        window.scrollTo(0, 0);
    };

    return (
        <Layout title="Elenco ordini">
            <div className="flex">
                <h3>Ordini</h3>

                <div className="w-64 ml-auto">
                    <div className="text-right text-sm mb-1">Ordina ordini per</div>
                    <Select onChange={val => fetch(cursor, val.target.value)}>
                        <option value="date_DESC">Data decrescente</option>
                        <option value="date_ASC">Data crescente</option>
                        <option value="orderno_ASC">N° ordine A-Z</option>
                        <option value="orderno_DESC">N° ordine Z-A</option>
                        <option value="owner_ASC">Cliente A-Z</option>
                        <option value="owner_DESC">Cliente Z-A</option>
                    </Select>
                </div>
                <div className="ml-4 flex items-end border-l">
                    <Link to="/orders/export">
                        <i className="las la-file-download la-3x" />
                    </Link>
                </div>
            </div>

            {query.fetching && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl">Caricamento...</span>
                </div>
            )}

            {query.error && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl text-vdp-red">Si è verificato un errore nel gestire la richiesta</span>
                </div>
            )}
            {query.data && (
                <>
                    <table className="mt-4 table-auto w-full">
                        <thead>
                            <tr>
                                <th className="w-12 text-xl font-semibold">#</th>
                                <th className="px-4 py-2 text-xl font-semibold text-left">Num. Ordine</th>
                                <th className="px-4 py-2 text-xl font-semibold text-left">Data ordine</th>
                                <th className="px-4 py-2 text-xl font-semibold text-left">Cliente</th>
                                <th className="w-1/12 px-4 py-2 text-xl font-semibold">Azioni</th>
                            </tr>
                        </thead>
                        <tbody>
                            {query.data.orderADList.edges.map((o: Order, i: number) => (
                                <tr key={o.id} className="odd:bg-vdp-beige">
                                    <td className="p-2 text-center">{cursor + i + 1}</td>
                                    <td className="p-4">{o.orderno}</td>
                                    <td className="p-4">{dayjs(o.date).format('DD/MM/YYYY')}</td>
                                    <td className="p-4">{o.owner.profile.completename}</td>
                                    <td className="p-4 border-l border-gray-300 flex">
                                        <Link to={`/orders/view/${o.id}`} className="flex-1 button small vdp-green">
                                            Dettagli
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="mt-8 flex justify-center">
                        <div className="border border-gray-700 rounded flex">
                            {cursor > 0 && (
                                <button className="block w-32 p-4 text-center font-semibold" onClick={() => fetch(cursor - limit)}>
                                    INDIETRO
                                </button>
                            )}

                            {query.data.orderADList.pageInfo.hasNextPage && (
                                <button
                                    className="even:border-l border-gray-700 block w-32 p-4 text-center font-semibold"
                                    onClick={() => fetch(query.data.orderADList.pageInfo.endCursor)}
                                >
                                    AVANTI
                                </button>
                            )}
                        </div>
                    </div>
                </>
            )}
        </Layout>
    );
};
