import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import notification from '../../notification';

import type { News } from '@gvdp/common/News';

import { Layout } from '../../components/_layout';
import WYSIWYGEditor from '../../components/Wysiwyg';

type Form = {
    title: string;
    abstract: string;
    body: string;
};

export const ManageNews: React.FC = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        setValue,
        control,
        formState: { errors }
    } = useForm<Form>();

    const { id } = useParams<{ id: string }>();

    const [image, setImage] = useState<string>();

    const [query] = useQuery({
        query: /* GraphQL */ `
            query ($id: ID!) {
                newsADView(id: $id) {
                    title
                    abstract
                    body
                    image {
                        url
                    }
                }
            }
        `,
        variables: { id },
        pause: !id
    });

    useEffect(() => {
        if (!query.fetching && query.data && query.data.newsADView) {
            const data = query.data.newsADView as News;
            setValue('title', data.title, { shouldValidate: true, shouldDirty: true });
            setValue('abstract', data.abstract, { shouldValidate: true, shouldDirty: true });
            setValue('body', data.body, { shouldValidate: true, shouldDirty: true });

            if (data.image) {
                setImage(data.image.url);
            }
        }
    }, [query, setValue]);

    const [, manageNews] = useMutation(/* GraphQL */ `
        mutation ($id: ID, $input: NewsInput!) {
            manageNews(id: $id, input: $input)
        }
    `);

    const onSubmit = async (input: Form) => {
        const result = await manageNews({ id, input });

        if (result.error) {
            notification.error(result.error);
        } else {
            if (!id) {
                navigate(`/news/manage/${result.data.manageNews}`, { replace: true });
            }

            notification.success('News salvata con successo');
        }
    };

    const [, upload] = useMutation(/* GraphQL */ `
        mutation ($id: ID!, $file: Upload!) {
            addNewsImage(id: $id, file: $file)
        }
    `);

    const imageADD = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];

            const result = await upload({ id, file });

            if (result.error) {
                notification.error(result.error);
            } else {
                setImage(result.data.addNewsImage);
                notification.success('Immagine inserita con successo!');
            }
        }
    };

    const [, removeImage] = useMutation(/* GraphQL */ `
        mutation ($id: ID!) {
            removeNewsImage(id: $id)
        }
    `);

    const imageRM = async () => {
        if (window.confirm('Sei sicuro di voler eliminare questa immagine?')) {
            const result = await removeImage({ id });

            if (result.error) {
                notification.error(result.error);
            } else {
                setImage(undefined);
                notification.success('Immagine rimossa con successo!');
            }
        }
    };

    return (
        <Layout title="Gestisci news">
            <h2>Gestisci news</h2>

            <div className="flex">
                <form className="mt-4 flex-1" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex">
                        <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="title">
                            Titolo *
                        </label>
                        <div className="flex-1">
                            <input {...register('title', { required: true })} placeholder="Titolo" className="input" />
                            {errors.title && <div className="input-error">Il titolo è obbligatorio</div>}
                        </div>
                    </div>
                    <div className="flex mt-4">
                        <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="abstract">
                            Abstract *
                        </label>
                        <div className="flex-1">
                            <input {...register('abstract', { required: true })} placeholder="Abstract" className="input" />
                            {errors.abstract && <div className="input-error">L&apos;abstract è obbligatorio</div>}
                        </div>
                    </div>
                    <div className="flex mt-4">
                        <label className="font-bold w-2/12 pr-4 flex justify-end mt-1" htmlFor="body">
                            Corpo *
                        </label>
                        <div className="flex-1">
                            <div className="bg-vdp-beige rounded">
                                <Controller
                                    render={({ field }) => <WYSIWYGEditor value={field.value} onChange={field.onChange} />}
                                    name="body"
                                    control={control}
                                    rules={{ required: true }}
                                />
                            </div>
                            {errors.body && <div className="input-error">Il corpo è obbligatorio</div>}
                        </div>
                    </div>

                    <button type="submit" className="button vdp-green w-full mt-4">
                        Salva
                    </button>
                </form>
                {id && (
                    <div className="w-64 h-64 flex flex-col px-4">
                        {image ? (
                            <>
                                <div className="flex justify-center items-center">
                                    <img src={image} alt="Immagine news" title="Immagine news" />
                                </div>
                                <button type="button" className="button vdp-red w-full mt-2" onClick={() => imageRM()}>
                                    Elimina
                                </button>
                            </>
                        ) : (
                            <>
                                <h4 className="text-2xl mb-2">Carica immagine</h4>
                                <input type="file" id="file" className="hidden" onChange={evt => imageADD(evt)} accept=".jpg,.png,.jpeg" />
                                <label htmlFor="file" className="uploader">
                                    +
                                </label>
                            </>
                        )}
                    </div>
                )}
            </div>
        </Layout>
    );
};
