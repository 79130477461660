import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import notification from '../../notification';

import type { Book, BookGQLFile } from '@gvdp/common/Book';
import type { Author } from '@gvdp/common/Author';
import type { Category } from '@gvdp/common/Category';
import type { Binding } from '@gvdp/common/Binding';

import { Layout } from '../../components/_layout';
import WYSIWYGEditor from '../../components/Wysiwyg';
import { Select } from '../../components/Select';

type Form = {
    isbn: string;
    title: string;
    authors: string[];
    category: string;
    binding: string;
    subtitle?: string;
    shortDescription?: string;
    description: string;
    pubblicationYear: number;
    language: string;
    pages: number;
    showInHome: boolean;
    hero: boolean;
    availability: number;
    price: PriceForm;
};

type PriceForm = {
    price: number;
    tax: number;
    discount?: number;
};

export const ManageBook: React.FC = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        setValue,
        control,
        formState: { errors }
    } = useForm<Form>();

    const { id } = useParams<{ id: string }>();

    const [images, setImages] = useState<BookGQLFile[]>();

    const [authors] = useQuery({
        query: /* GraphQL */ `
            query {
                authors(limit: 999) {
                    edges {
                        id
                        firstname
                        lastname
                    }
                }
            }
        `
    });

    const [categories] = useQuery({
        query: /* GraphQL */ `
            query {
                categories {
                    edges {
                        id
                        name
                        order
                    }
                }
            }
        `
    });

    const [bindings] = useQuery({
        query: /* GraphQL */ `
            query {
                bindings {
                    edges {
                        id
                        name
                    }
                }
            }
        `
    });

    const [book] = useQuery({
        query: /* GraphQL */ `
            query ($id: ID!) {
                bookADView(id: $id) {
                    isbn
                    title
                    category {
                        id
                    }
                    authors {
                        id
                    }
                    binding {
                        id
                    }
                    subtitle
                    shortDescription
                    description
                    pubblicationYear
                    language
                    pages
                    showInHome
                    hero
                    images {
                        id
                        image {
                            url
                        }
                        isHero
                        isPrincipal
                    }
                    availability
                    price {
                        price
                        tax
                        discount
                    }
                }
            }
        `,
        variables: { id },
        pause: !id
    });

    if (book.error || authors.error || categories.error || bindings.error) {
        notification.error(book.error || authors.error || categories.error || bindings.error || 'Si è verificato un errore imprevisto');
    }

    useEffect(() => {
        if (
            !authors.fetching &&
            authors.data &&
            !categories.fetching &&
            categories.data &&
            !bindings.fetching &&
            bindings.data &&
            !book.fetching &&
            book.data &&
            book.data.bookADView
        ) {
            const data = book.data.bookADView as Book;
            setValue('isbn', data.isbn, { shouldValidate: true, shouldDirty: true });
            setValue('title', data.title, { shouldValidate: true, shouldDirty: true });
            setValue(
                'authors',
                data.authors.map(a => a.id as string),
                { shouldValidate: true, shouldDirty: true }
            );
            setValue('category', data.category.id as string, { shouldValidate: true, shouldDirty: true });
            setValue('binding', data.binding.id as string, { shouldValidate: true, shouldDirty: true });
            setValue('subtitle', data.subtitle, { shouldValidate: true, shouldDirty: true });
            setValue('shortDescription', data.shortDescription, { shouldValidate: true, shouldDirty: true });
            setValue('description', data.description, { shouldValidate: true, shouldDirty: true });
            setValue('pubblicationYear', data.pubblicationYear, { shouldValidate: true, shouldDirty: true });
            setValue('language', data.language, { shouldValidate: true, shouldDirty: true });
            setValue('pages', data.pages, { shouldValidate: true, shouldDirty: true });
            setValue('showInHome', data.showInHome, { shouldValidate: true, shouldDirty: true });
            setValue('hero', data.hero, { shouldValidate: true, shouldDirty: true });
            setValue('availability', data.availability, { shouldValidate: true, shouldDirty: true });
            setValue('price.price', data.price.price, { shouldValidate: true, shouldDirty: true });
            setValue('price.tax', data.price.tax, { shouldValidate: true, shouldDirty: true });
            if (data.price.discount) {
                setValue('price.discount', data.price.discount, { shouldValidate: true, shouldDirty: true });
            }

            if (data.images) {
                setImages(data.images);
            }
        }
    }, [authors, categories, bindings, book, setValue]);

    const [, manageBook] = useMutation(/* GraphQL */ `
        mutation ($id: ID, $input: BookInput!) {
            manageBook(id: $id, input: $input)
        }
    `);

    const onSubmit = async (input: Form) => {
        input.pubblicationYear = +input.pubblicationYear;
        input.pages = +input.pages;
        input.availability = +input.availability;
        input.price.price = +input.price.price;
        input.price.tax = +input.price.tax;
        if (input.price.discount) {
            input.price.discount = +input.price.discount;
        } else {
            delete input.price.discount;
        }

        if (!input.subtitle) {
            delete input.subtitle;
        }

        if (!input.shortDescription) {
            delete input.shortDescription;
        }

        const result = await manageBook({ id, input });

        if (result.error) {
            notification.error(result.error);
        } else {
            if (!id) {
                navigate(`/books/manage/${result.data.manageBook}`, { replace: true });
            }

            notification.success('Libro salvato con successo!');
        }
    };

    const [, upload] = useMutation(/* GraphQL */ `
        mutation ($id: ID!, $file: Upload!) {
            addBookImage(id: $id, file: $file) {
                id
                image {
                    url
                }
                isHero
                isPrincipal
            }
        }
    `);

    const imageADD = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];

            const result = await upload({ id, file });

            if (result.error) {
                notification.error(result.error);
            } else {
                if (!images) {
                    setImages([result.data.addBookImage]);
                } else {
                    setImages([result.data.addBookImage, ...images]);
                }

                notification.success('Immagine aggiunta con successo!');
            }
        }
    };

    const [, removeImage] = useMutation(/* GraphQL */ `
        mutation ($id: ID!, $imageId: ID!) {
            removeBookImage(id: $id, imageId: $imageId)
        }
    `);

    const imageRM = async (imageId: string) => {
        if (window.confirm('Sei sicuro di voler eliminare questa immagine?')) {
            const result = await removeImage({ id, imageId });

            if (result.error) {
                notification.error(result.error);
            } else {
                const temp = [...(images || [])];
                const index = temp.findIndex(i => i.id === imageId);
                if (index > -1) {
                    temp?.splice(index, 1);
                    setImages(temp);
                }

                notification.success('Immagine rimossa con successo!');
            }
        }
    };

    const [, setPrincipal] = useMutation(/* GraphQL */ `
        mutation ($id: ID!, $imageId: ID!) {
            setPrincipalImage(id: $id, imageId: $imageId)
        }
    `);

    const changePrincipal = async (imageId: string) => {
        if (window.confirm('Sei sicuro di voler impostare questa immagine come principale?')) {
            const result = await setPrincipal({ id, imageId });

            if (result.error) {
                notification.error(result.error);
            } else {
                const temp = [...(images || [])];
                temp.forEach(i => {
                    delete i.isPrincipal;
                    if (i.id === imageId) {
                        i.isPrincipal = true;
                    }
                });

                setImages(temp);

                notification.success('Immagine principale impostata con successo!');
            }
        }
    };

    const [, setHero] = useMutation(/* GraphQL */ `
        mutation ($id: ID!, $imageId: ID!) {
            setHeroImage(id: $id, imageId: $imageId)
        }
    `);

    const changeHero = async (imageId: string) => {
        if (window.confirm("Sei sicuro di voler modificarfe l'immagine figata ultra power?")) {
            const result = await setHero({ id, imageId });

            if (result.error) {
                notification.error(result.error);
            } else {
                const temp = [...(images || [])];
                temp.forEach(i => {
                    delete i.isHero;
                    if (i.id === imageId) {
                        i.isHero = true;
                    }
                });

                setImages(temp);

                notification.success('Immagine figata ultra power modificata con successo!');
            }
        }
    };

    return (
        <Layout title="Gestisci libro">
            <h2>Gestisci libro</h2>
            <span className="italic font-bold text-xs">* Campi obbligatori</span>

            <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="isbn">
                        ISBN *
                    </label>
                    <div className="flex-1">
                        <input {...register('isbn', { required: true })} placeholder="ISBN" className="input" />
                        {errors.isbn && <div className="input-error">L&apos;ISBN è obbligatorio</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="title">
                        Titolo *
                    </label>
                    <div className="flex-1">
                        <input {...register('title', { required: true })} placeholder="Titolo" className="input" />
                        {errors.title && <div className="input-error">Il titolo è obbligatorio</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="subtitle">
                        Sottotitolo
                    </label>
                    <div className="flex-1">
                        <input {...register('subtitle')} placeholder="Sottotitolo" className="input" />
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="shortDescription">
                        Descrizione breve
                    </label>
                    <div className="flex-1">
                        <input {...register('shortDescription')} placeholder="Descrizione breve" className="input" />
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end mt-1" htmlFor="description">
                        Descrizione *
                    </label>
                    <div className="flex-1 bg-vdp-beige rounded">
                        <Controller
                            render={({ field }) => <WYSIWYGEditor value={field.value} onChange={field.onChange} />}
                            name="description"
                            control={control}
                            rules={{ required: true }}
                        />
                        {errors.description && <div className="input-error">La descrizione è obbligatoria</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end mt-1" htmlFor="authors">
                        Autori *
                    </label>
                    <div className="flex-1">
                        <select className="w-full bg-vdp-beige rounded p-4" multiple {...register('authors', { required: true })}>
                            {authors.data &&
                                authors.data.authors.edges.map((a: Author) => (
                                    <option key={a.id} value={a.id}>
                                        {a.firstname} {a.lastname}
                                    </option>
                                ))}
                        </select>
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="category">
                        Categoria *
                    </label>
                    <div className="flex-1">
                        <Select {...register('category', { required: true })}>
                            <option value="">...</option>
                            {categories.data &&
                                categories.data.categories.edges.map((c: Category) => (
                                    <option key={c.id} value={c.id}>
                                        {c.name}
                                    </option>
                                ))}
                        </Select>

                        {errors.category && <div className="input-error">La categoria è obbligatoria</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="binding">
                        Rilegatura *
                    </label>
                    <div className="flex-1">
                        <Select {...register('binding', { required: true })}>
                            <option value="">...</option>
                            {bindings.data &&
                                bindings.data.bindings.edges.map((b: Binding) => (
                                    <option key={b.id} value={b.id}>
                                        {b.name}
                                    </option>
                                ))}
                        </Select>

                        {errors.binding && <div className="input-error">La rilegatura è obbligatoria</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="pubblicationYear">
                        Anno di pubblicazione *
                    </label>
                    <div className="flex-1">
                        <input
                            {...register('pubblicationYear', {
                                required: "L'anno di pubblicazone è obbligatorio",
                                min: { value: 1, message: "Un libro dall'anno zero?" }
                            })}
                            placeholder="Anno di pubblicazione"
                            className="input"
                            type="number"
                            min="1"
                            step="1"
                        />
                        {errors.pubblicationYear && <div className="input-error">{errors.pubblicationYear.message}</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="language">
                        Lingua *
                    </label>
                    <div className="flex-1">
                        <input {...register('language', { required: true })} placeholder="Lingua" className="input" />
                        {errors.language && <div className="input-error">La lingua è obbligatoria</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="pages">
                        Pagine *
                    </label>
                    <div className="flex-1">
                        <input
                            {...register('pages', {
                                required: 'Le pagine sono obbligatorie',
                                min: { value: 1, message: 'Le pagine devono essere >= 1' }
                            })}
                            placeholder="Pagine"
                            className="input"
                            type="number"
                            min="1"
                            step="1"
                        />
                        {errors.pages && <div className="input-error">{errors.pages.message}</div>}
                    </div>
                </div>
                <div className="mt-4 p-4 bg-vdp-beige flex items-center justify-center">
                    <input type="checkbox" className="mr-2" {...register('showInHome')} /> Mostra in home
                    <input type="checkbox" className="ml-4 mr-2" {...register('hero')} /> Figata ultra power
                </div>

                <h3 className="text-center mt-4">Disponibilità</h3>

                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="availability">
                        Disponibilità *
                    </label>
                    <div className="flex-1">
                        <input
                            {...register('availability', {
                                required: 'La disponibilità è obbligatoria',
                                min: { value: 0, message: 'La disponibilità deve essere >= a 0' }
                            })}
                            placeholder="Disponibilità"
                            className="input"
                            type="number"
                            min="0"
                            step="1"
                        />
                        {errors.availability && <div className="input-error">{errors.availability.message}</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="price">
                        Prezzo *
                    </label>
                    <div className="flex-1">
                        <input
                            {...register('price.price', {
                                required: 'Il prezzo è obbligatorio',
                                min: { value: 0, message: 'Il prezzo deve essere >= a 0' }
                            })}
                            placeholder="Prezzo"
                            className="input"
                            type="number"
                            min="0"
                            step="0.1"
                        />
                        {errors.price?.price && <div className="input-error">{errors.price?.price?.message}</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="tax">
                        IVA *
                    </label>
                    <div className="flex-1">
                        <input
                            {...register('price.tax', {
                                required: "L'IVA è obbligatoria",
                                min: { value: 0, message: "L'IVA almeno a zero" }
                            })}
                            placeholder="IVA"
                            className="input"
                            type="number"
                            min="0"
                            step="1"
                        />
                        {errors.price?.tax && <div className="input-error">{errors.price?.tax?.message}</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="discount">
                        Sconto
                    </label>
                    <div className="flex-1">
                        <input {...register('price.discount')} placeholder="Sconto (facoltativo)" className="input" type="number" min="0" max="99" step="1" />
                        {errors.price?.discount && <div className="input-error">Lo sconto deve essere tra 0 e 99</div>}
                    </div>
                </div>

                <button type="submit" className="button vdp-green w-full mt-4">
                    Salva
                </button>
            </form>

            {id && (
                <>
                    <h3 className="mt-4">Immagini</h3>
                    <div className="flex">
                        {images &&
                            images.map((image: BookGQLFile, i: number) => (
                                <div key={i} className="w-64 bg-gray-200 p-4 mr-4">
                                    <img src={image.image.url} alt="Immagine libro" title="Immagine libro" />
                                    <div className="mt-4">
                                        <div>
                                            <input
                                                type="checkbox"
                                                id={`isPrincipal-${i}`}
                                                checked={image.isPrincipal || false}
                                                disabled={image.isPrincipal}
                                                onChange={() => changePrincipal(image.id)}
                                                className="mr-2"
                                            />
                                            Principale
                                        </div>
                                        <div className="mt-2">
                                            <input type="checkbox" id={`"isHero-${i}"`} checked={image.isHero || false} onChange={() => changeHero(image.id)} className="mr-2" />
                                            Figata ultra power
                                        </div>
                                    </div>
                                    <button type="button" className="block bg-vdp-red px-4 py-2 text-white text-center uppercase w-full mt-4" onClick={() => imageRM(image.id)}>
                                        Elimina
                                    </button>
                                </div>
                            ))}

                        <div className="w-64 h-64 bg-gray-200 p-4">
                            <input type="file" id="file" className="hidden" onChange={evt => imageADD(evt)} accept=".jpg,.png,.jpeg" />
                            <label
                                htmlFor="file"
                                className="w-full h-full border-4 border-dashed border-vdp-green rounded-lg flex items-center justify-center text-6xl text-vdp-green
                cursor-pointer"
                            >
                                +
                            </label>
                        </div>
                    </div>
                </>
            )}
        </Layout>
    );
};
