import type { ReactElement } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { RequireAuth } from './RequireAuth';

import { Home } from './pages/home';
import { Login } from './pages/login';
import { AuthorList } from './pages/authors';
import { ManageAuthor } from './pages/authors/manage';
import { BindingList } from './pages/bindings';
import { ManageBinding } from './pages/bindings/manage';
import { CategoryList } from './pages/categories';
import { ManageCategory } from './pages/categories/manage';
import { CouponList } from './pages/coupons';
import { AddCoupon } from './pages/coupons/add';
import { ShippingList } from './pages/shippings';
import { ManageShipping } from './pages/shippings/manage';
import { UserList } from './pages/users';
import { ViewUser } from './pages/users/view';
import { OrderList } from './pages/orders';
import { OrderExport } from './pages/orders/export';
import { ViewOrder } from './pages/orders/view';
import { NewsList } from './pages/news';
import { ManageNews } from './pages/news/manage';
import { BookList } from './pages/books';
import { ManageBook } from './pages/books/manage';

export default function App(): ReactElement {
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route element={<RequireAuth />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/authors">
                            <Route index element={<AuthorList />} />
                            <Route path="manage">
                                <Route index element={<ManageAuthor />} />
                                <Route path=":id" element={<ManageAuthor />} />
                            </Route>
                        </Route>
                        <Route path="/bindings">
                            <Route index element={<BindingList />} />
                            <Route path="manage">
                                <Route index element={<ManageBinding />} />
                                <Route path=":id" element={<ManageBinding />} />
                            </Route>
                        </Route>
                        <Route path="/categories">
                            <Route index element={<CategoryList />} />
                            <Route path="manage">
                                <Route index element={<ManageCategory />} />
                                <Route path=":id" element={<ManageCategory />} />
                            </Route>
                        </Route>
                        <Route path="/coupons">
                            <Route index element={<CouponList />} />
                            <Route path="add" element={<AddCoupon />} />
                        </Route>
                        <Route path="/shippings">
                            <Route index element={<ShippingList />} />
                            <Route path="manage">
                                <Route index element={<ManageShipping />} />
                                <Route path=":id" element={<ManageShipping />} />
                            </Route>
                        </Route>
                        <Route path="/users">
                            <Route index element={<UserList />} />
                            <Route path="view/:id" element={<ViewUser />} />
                        </Route>
                        <Route path="/orders">
                            <Route index element={<OrderList />} />
                            <Route path="export" element={<OrderExport />} />
                            <Route path="view/:id" element={<ViewOrder />} />
                        </Route>
                        <Route path="/news">
                            <Route index element={<NewsList />} />
                            <Route path="manage">
                                <Route index element={<ManageNews />} />
                                <Route path=":id" element={<ManageNews />} />
                            </Route>
                        </Route>
                        <Route path="/books">
                            <Route index element={<BookList />} />
                            <Route path="manage">
                                <Route index element={<ManageBook />} />
                                <Route path=":id" element={<ManageBook />} />
                            </Route>
                        </Route>
                    </Route>
                </Routes>
            </Router>

            <ToastContainer
                key="ToastContainer"
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                closeButton={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                theme="colored"
                pauseOnHover={false}
            />
        </>
    );
}
