import type { ChangeEvent } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import dayjs from 'dayjs';

import notification from '../../notification';

import type { OrderBook } from '@gvdp/common/Order';
import type { Price } from '@gvdp/common/Book';

import { Layout } from '../../components/_layout';

export const ViewOrder: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    const [query] = useQuery({
        query: /* GraphQL */ `
            query($id: ID!) {
                orderADView(id: $id) {
                    owner {
                        profile {
                            email
                            completename
                            invoiceInfo {
                                businessName
                                vatId
                                CF
                            }
                        }
                        invoiceAddress {
                            completeAddress
                            consigneeName
                            phone
                        }
                        shippingAddress {
                            completeAddress
                            consigneeName
                            phone
                        }
                    }
                    orderno
                    date
                    note
                    isPaid
                    isCancelled
                    isShipped
                    requireShipping
                    shipping {
                        method
                        description
                        packageprice
                        shippingprice
                    }
                    coupon {
                        code
                        discount
                    }
                    books {
                        book {
                            id
                            title
                            images {
                                image {
                                    url
                                }
                            }
                        }
                        quantity
                        price {
                            price
                            tax
                            discount
                        }
                    }
                }
            }
        `,
        variables: { id },
        pause: !id
    });

    if (query.error) {
        notification.error(query.error);
    }

    const [, setFlag] = useMutation(/* GraphQL */ `
        mutation($id: ID!, $flag: StatusFlag!, $value: Boolean!) {
            changeFlag(id: $id, flag: $flag, value: $value) {
                id
            }
        }
    `);

    const changeFlag = async (event: ChangeEvent<HTMLInputElement>) => {
        if (window.confirm('Sei sicuro di voler cambiare questa proprietà?')) {
            const flag = event.target.id;
            const value = event.target.checked;

            const result = await setFlag({ id, flag, value });

            if (result.error) {
                notification.error(result.error);
            }
        }
    };

    const calculateTax = (price: number, tax: number) => {
        return (tax / 100) * price;
    };

    const calculateDiscount = (price: number, discount: number) => {
        return (discount / 100) * price;
    };

    const bankersRound = (n: number, d = 0) => {
        const x = n * 10 ** d;
        const r = Math.round(x);
        const br = (x > 0 ? x : -x) % 1 === 0.5 ? (r % 2 ? r : r - 1 === 0) : r;
        return (br as number) / 10 ** d;
    };

    const calculateTotal = (quantity: number, price: Price) => {
        const taxed = price.price + calculateTax(price.price, price.tax);
        return quantity * bankersRound(taxed - calculateDiscount(taxed, price.discount || 0), 1);
    };

    return (
        <Layout title="Dettagli ordine">
            <h2>Dettagli ordine</h2>

            {query.fetching && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl">Caricamento...</span>
                </div>
            )}

            {query.error && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl text-vdp-red">Si è verificato un errore nel gestire la richiesta</span>
                </div>
            )}
            {query.data && (
                <>
                    <h1 className="text-4xl mt-2">Ordine {query.data.orderADView.orderno}</h1>
                    <h4 className="text-xl">del {dayjs(query.data.orderADView.date).format('DD/MM/YYYY HH:mm')}</h4>

                    <div className="flex border border-gray-600 mt-4">
                        <div className="flex-grow bg-gray-300 p-4 w-1/2">
                            <b className="text-xl">Fatturare a:</b>
                            <br />
                            {query.data.orderADView.owner.profile.invoiceInfo && query.data.orderADView.owner.profile.invoiceInfo.businessName ? (
                                <b>Ragione Sociale: {query.data.orderADView.owner.profile.invoiceInfo.businessName}</b>
                            ) : (
                                <span>{query.data.orderADView.owner.profile.completename}</span>
                            )}
                            {query.data.orderADView.owner.profile.invoiceInfo && query.data.orderADView.owner.profile.invoiceInfo.vatId && (
                                <>
                                    <br />
                                    P.IVA: {query.data.orderADView.owner.profile.invoiceInfo.vatId}
                                </>
                            )}
                            {query.data.orderADView.owner.profile.invoiceInfo && query.data.orderADView.owner.profile.invoiceInfo.CF && (
                                <>
                                    <br />
                                    CF: {query.data.orderADView.owner.profile.invoiceInfo.CF}
                                </>
                            )}
                            <br />
                            {query.data.orderADView.owner.invoiceAddress.completeAddress}
                            <br />
                            Tel: {query.data.orderADView.owner.invoiceAddress.phone || ''}
                        </div>
                        <div className="flex-grow bg-gray-200 p-4 w-1/2 border-l border-gray-600">
                            <b className="text-xl">Spedire a:</b>
                            <br />
                            {query.data.orderADView.owner.shippingAddress.consigneeName ||
                                (query.data.orderADView.owner.profile.invoiceInfo && query.data.orderADView.owner.profile.invoiceInfo.businessName) ||
                                query.data.orderADView.owner.profile.completename}
                            <br />
                            {query.data.orderADView.owner.shippingAddress.completeAddress}
                            <br />
                            Tel: {query.data.orderADView.owner.shippingAddress.phone || ''}
                        </div>
                    </div>

                    <div className="flex mt-4 border border-gray-400">
                        <div className="flex-1 p-4 flex">
                            <input type="checkbox" id="isPaid" checked={query.data.orderADView.isPaid} onChange={evt => changeFlag(evt)} />
                            <label htmlFor="isPaid" className="flex items-center">
                                <i className="las la-money-bill-wave la-2x ml-2 mr-1" />
                                Pagato
                            </label>
                        </div>
                        <div className="flex-1 p-4 border-l border-gray-400 flex">
                            <input type="checkbox" id="isShipped" checked={query.data.orderADView.isShipped} onChange={evt => changeFlag(evt)} />
                            <label htmlFor="isShipped" className="flex items-center">
                                <i className="las la-shipping-fast la-2x ml-2 mr-1" />
                                Spedito
                            </label>
                        </div>
                        <div className="flex-1 p-4  border-l border-gray-400 flex">
                            <input type="checkbox" id="isCancelled" checked={query.data.orderADView.isCancelled} onChange={evt => changeFlag(evt)} />
                            <label htmlFor="isCancelled" className="flex items-center">
                                <i className="las la-skull-crossbones la-2x ml-2 mr-1" />
                                Annullato
                            </label>
                        </div>
                    </div>

                    <h2 className="text-3xl mt-4">Prodotti</h2>
                    <table className="table-auto w-full">
                        <thead>
                            <tr>
                                <th className="w-1/2" />
                                <th className="px-4 py-2 text-left">Quantità</th>
                                <th className="px-4 py-2 text-left">Prezzo netto unitario</th>
                                <th className="px-4 py-2 text-left">IVA</th>
                                <th className="px-4 py-2 text-left">Sconto</th>
                                <th className="px-4 py-2 text-left">Tot.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {query.data.orderADView.books.map((b: OrderBook, i: number) => (
                                <tr key={i} className="odd:bg-vdp-beige ">
                                    <td className="p-4 flex items-center">
                                        {b.book.images && b.book.images.length > 0 && (
                                            <img className="w-8 mr-2" src={b.book.images[0].image.url} alt={b.book.title} title={b.book.title} />
                                        )}
                                        <Link to={`/books/edit/${b.book.id}`}>{b.book.title}</Link>
                                    </td>
                                    <td className="p-4">{b.quantity}</td>
                                    <td className="p-4">€ {b.price.price.toFixed(2)}</td>
                                    <td className="p-4">{b.price.tax}%</td>
                                    <td className="p-4">{b.price.discount ? `${b.price.discount}%` : '-'}</td>
                                    <td className="p-4">€{calculateTotal(b.quantity, b.price).toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="text-right mt-2 border-t p-3">
                        <b>Totale prodotti:</b>
                        <br />€
                        {query.data.orderADView.books.reduce((acc: number, curr: OrderBook) => acc + calculateTotal(curr.quantity, curr.price), 0).toFixed(2)}
                        <br />
                        <b>Spedizione</b>
                        <br />
                        <span className="text-vdp-red">{query.data.orderADView.shipping.method}</span>
                        <br />
                        <b>Spese imballo</b>
                        <br />€
                        {(
                            query.data.orderADView.books.reduce((acc: number, curr: OrderBook) => acc + curr.quantity, 0) *
                            query.data.orderADView.shipping.packageprice
                        ).toFixed(2)}
                        <br />
                        <b>Spese spedizione</b>
                        <br />€{query.data.orderADView.shipping.shippingprice.toFixed(2)}
                        <h2 className="text-3xl mt-4">Totale Ordine:</h2>€
                        {(
                            query.data.orderADView.books.reduce((acc: number, curr: OrderBook) => acc + calculateTotal(curr.quantity, curr.price), 0) +
                            query.data.orderADView.books.reduce((acc: number, curr: OrderBook) => acc + curr.quantity, 0) *
                                query.data.orderADView.shipping.packageprice +
                            query.data.orderADView.shipping.shippingprice
                        ).toFixed(2)}
                    </div>
                </>
            )}
        </Layout>
    );
};
