import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import dayjs from 'dayjs';

import notification from '../../notification';

import type { Book } from '@gvdp/common/Book';

import { Layout } from '../../components/_layout';
import { Select } from '../../components/Select';

export const BookList: React.FC = () => {
    const limit = 20;
    const [cursor, setCursor] = useState(0);
    const [orderBy, setOrderBy] = useState('title_ASC');

    const [query] = useQuery({
        query: /* GraphQL */ `
            query($limit: Int!, $cursor: Int, $orderBy: BookOrderByInput) {
                bookADList(limit: $limit, cursor: $cursor, orderBy: $orderBy) {
                    edges {
                        id
                        title
                        availability
                        datecreated
                        category {
                            name
                        }
                        authors {
                            firstname
                            lastname
                        }
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                }
            }
        `,
        variables: { limit, cursor, orderBy }
    });

    if (query.error) {
        notification.error(query.error);
    }

    const fetch = (cur: number, order?: string) => {
        setCursor(+(cur !== 0) && (cur || cursor));
        if (order) {
            setOrderBy(order);
        }

        window.scrollTo(0, 0);
    };

    const [, deleteBook] = useMutation(/* GraphQL */ `
        mutation($id: ID!) {
            deleteBook(id: $id) {
                id
            }
        }
    `);

    const doDelete = async (id: string) => {
        if (window.confirm('Sei sicuro di voler eliminare questo libro?')) {
            const result = await deleteBook({ id });

            if (result.error) {
                notification.error(result.error);
            } else {
                notification.success('Libro eliminato con successo');
            }
        }
    };

    return (
        <Layout title="Elenco libri">
            <div className="flex">
                <div className="flex items-center">
                    <h3>Libri</h3>
                    <Link to="/books/manage" className="ml-2 text-3xl text-vdp-green">
                        <i className="las la-plus-circle" />
                    </Link>
                </div>

                <div className="w-64 ml-auto">
                    <div className="text-right text-sm mb-1">Ordina libri per</div>
                    <Select onChange={val => fetch(cursor, val.target.value)}>
                        <option value="title_ASC">Titolo A-Z</option>
                        <option value="title_DESC">Titolo Z-A</option>
                        <option value="datecreated_ASC">Data creazione crescente</option>
                        <option value="datecreated_DESC">Data creazione decrescente</option>
                    </Select>
                </div>
            </div>

            {query.fetching && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl">Caricamento...</span>
                </div>
            )}

            {query.error && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl text-vdp-red">Si è verificato un errore nel gestire la richiesta</span>
                </div>
            )}
            {query.data && (
                <>
                    <table className="mt-4 table-auto w-full">
                        <thead>
                            <tr>
                                <th className="w-12 text-xl font-semibold">#</th>
                                <th className="px-4 py-2 text-xl font-semibold text-left">Titolo</th>
                                <th className="px-4 py-2 text-xl font-semibold text-left">Categoria</th>
                                <th className="px-4 py-2 text-xl font-semibold text-left">Autori</th>
                                <th className="px-4 py-2 text-xl font-semibold text-left">Disp.</th>
                                <th className="px-4 py-2 text-xl font-semibold text-left">Data ins.</th>
                                <th className="w-1/5 px-4 py-2 text-xl font-semibold">Azioni</th>
                            </tr>
                        </thead>
                        <tbody>
                            {query.data.bookADList.edges.map((b: Book, i: number) => (
                                <tr key={b.id} className="odd:bg-vdp-beige">
                                    <td className="p-2 text-center">{cursor + i + 1}</td>
                                    <td className="p-4">{b.title}</td>
                                    <td className="p-4">{b.category.name}</td>
                                    <td className="p-4">{b.authors.map(p => `${p.firstname} ${p.lastname}`).join(', ')}</td>
                                    <td className="p-4">{b.availability}</td>
                                    <td className="p-4">{dayjs(b.datecreated).format('DD/MM/YYYY')}</td>
                                    <td className="p-4 border-l border-gray-300 flex">
                                        <Link to={`/books/manage/${b.id}`} className="flex-1 button small vdp-green">
                                            Modifica
                                        </Link>
                                        &nbsp;
                                        <button className="flex-1 button small vdp-red" onClick={() => doDelete(b.id as string)}>
                                            Elimina
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="mt-8 flex justify-center">
                        <div className="border border-gray-700 rounded flex">
                            {cursor > 0 && (
                                <button className="block w-32 p-4 text-center font-semibold" onClick={() => fetch(cursor - limit)}>
                                    INDIETRO
                                </button>
                            )}

                            {query.data.bookADList.pageInfo.hasNextPage && (
                                <button
                                    className="even:border-l border-gray-700 block w-32 p-4 text-center font-semibold"
                                    onClick={() => fetch(query.data.bookADList.pageInfo.endCursor)}
                                >
                                    AVANTI
                                </button>
                            )}
                        </div>
                    </div>
                </>
            )}
        </Layout>
    );
};
